import { create } from 'apisauce';
import _ from 'lodash';
import Storage from 'utils/Storage';

const api = create( {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 600000,
} );

api.addRequestTransform( ( request ) => {
  const accessToken = Storage.get( 'grupoimpar:token' );
  if ( accessToken ) request.headers.Authorization = `Bearer ${accessToken}`;
} );

api.addResponseTransform( ( response: any ) => {
  const { data, ok } = response;

  if ( !ok ) {
    let errors = '';

    if ( response && response.status === 401 ) {
      Storage.clear( 'grupoimpar:token' );
      Storage.clear( 'persist:grupoimpar' );
      return setTimeout( () => {
        window.location.replace( '/login' );
      }, 100 );
    }

    if ( data ) {
      if ( data.form ) {
        const errorsObject: any = {};
        _.map( data.form.errors, ( error, key:string ) => {
          if ( !_.isEmpty( error ) ) {
            errorsObject[key] = error.join( '\n' );
          }
        } );
        errors = errorsObject;
      } else if ( data.errors ) {
        if ( _.isObject( data.errors ) && !_.isArray( data.errors ) ) {
          const arrayErrors: any = [];
          _.map( data.errors, ( error ) => {
            if ( _.isString( error ) ) arrayErrors.push( error );
            else if ( _.isArray( errors ) ) arrayErrors.push( error.join( '\n' ) );
          } );
          errors = arrayErrors.join( '\n' );
        } else if ( _.isArray( data.errors ) ) {
          errors = data.errors.join( '\n' );
        } else {
          errors = data.errors;
        }
      } else if ( data.message ) errors = data.message;
      else if ( data.error_description ) errors = data.error_description;
      else errors = data;
    }

    if ( !errors || _.isEmpty( errors ) ) errors = 'Se produjo un error al intentar conectarse al servidor';
    if ( response?.config?.method === 'get' && !_.isString( errors ) ) response.errors = 'Se produjo un error al intentar conectarse al servidor';
    else response.errors = errors;
  }
} );

export default api;
