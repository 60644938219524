import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { CellProps } from 'react-table';
import numeral from 'numeral';
import { formatCurrencyNoDecimal, formatPercentOneDecimal } from 'utils/formats';
import { DTable } from 'types/table';
import Table from 'components/Table';
import { useNavigate } from 'react-router-dom';
import { convertToSlug } from 'utils/utils';
import { FOUND_PROJECTS } from 'constants/routes';

type ProjectsProps = {
  fundId: string
  isLoading?:boolean;
  data:DTable;
  fetchData: ( params:any, pageSize:number )=>void ;
};
function Projects( {
  fundId,
  data,
  fetchData,
  isLoading,
}: ProjectsProps ) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Table
      columns={[
        {
          Header: t( 'name' ),
          accessor: 'codigoPromocion',
          Cell: ( { cell }:CellProps<any> ) => (
            <Button
              className="p-0"
              variant="link"
              onClick={() => {
                navigate( `${FOUND_PROJECTS}/${fundId}/${cell.row.original.promocionId}/${convertToSlug( cell.value )}` );
              }}
            >
              <span className="text--bold text--normal text--md">{cell.value}</span>
            </Button>
          ),
        },
        {
          className: 'text--lato',
          Header: t( 'start' ),
          accessor: 'start',
          customWidth: 100,
        },
        {
          className: 'text--lato',
          Header: t( 'end' ),
          accessor: 'end',
          customWidth: 100,
        },
        {
          Header: t( 'equity' ),
          accessor: 'fondosPropios',
          className: 'text--right text--lato',
          headerClassName: 'text--right',
          customWidth: 180,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value ).format( formatCurrencyNoDecimal ) ),
        },
        {

          Header: `${t( 'estimatedProfit' )}*`,
          headerClassName: 'text--right',
          accessor: 'estimatedBenefit',
          className: 'text--right text--lato',
          customWidth: 160,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value ).format( formatCurrencyNoDecimal ) ),
        },
        {
          headerClassName: 'text--right',
          Header: t( 'roi' ),
          accessor: 'roi',
          className: 'text--right text--lato',
          customWidth: 100,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value / 100 ).format( formatPercentOneDecimal ) ),
        },
        {
          headerClassName: 'text--right',
          Header: t( 'irr' ),
          accessor: 'tir',
          className: 'text--right text--lato',
          customWidth: 100,
          Cell: ( { cell }: CellProps<any> ) => (
            numeral( cell.value / 100 ).format( formatPercentOneDecimal ) ),
        },
      ]}
      isLoading={isLoading}
      total={data.totalItems}
      fetchData={fetchData}
      pageCount={data.pageCount}
      data={data.data}
      defaultPageSize={3}
      paginationOptions={['3', '10']}
      cardHeader={(
        <Row className="mb-3 p-0 m-0">
          <Col md={{ span: 10, order: 1 }} className="p-0 m-0">
            <h3 className="text-ellipsis text--xmmd text-title">{t( 'founds.projectsVehicles' )}</h3>
          </Col>
        </Row>
 )}
    />
  );
}

export default Projects;
