import React, { ReactNode } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import classnames from 'classnames';

type DarkTitleProps = {
  icon?: any;
  title?: string | ReactNode;
  subtitle?: string;
  subtitle2?: string;
  className?: string;
};
function DarkTile( {
  icon, title, subtitle, subtitle2, className,
}: DarkTitleProps ) {
  return (
    <Col xs={6} md={6} xl={3} className={classnames( 'p-2', className )}>
      <Card className="card--primary card--sm h-100" body>
        <Row className="card--primary__container">
          <Col xs="auto" className="pe-0 d-flex align-items-md-center">
            <div className="icon--rounded bg-white">
              <img src={icon} alt="" />
            </div>
          </Col>
          <Col className={classnames( 'pe-1', { 'mt-1': !subtitle2 } )}>
            <h2 className="text--gray5 d-none d-md-block text--lgx">{title}</h2>
            <p className="text--sm text--medium text--gray6 m-0">{subtitle}</p>
          </Col>
          <Col sm={12}>
            {!!subtitle2 && <p className="text--xxs text--gray7 m-0 d-none d-md-block">{subtitle2}</p>}
          </Col>
        </Row>
        <Row className="d-block d-md-none">
          <Col>
            <h2 className="text--gray5">{title}</h2>
            {!!subtitle2 && <p className="text--xxs text--gray7 m-0">{subtitle2}</p>}
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default DarkTile;
