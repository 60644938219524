import React, { useCallback, useContext } from 'react';
import AccountsView from 'views/Admin/Accounts';
import useFetchTableData from 'hooks/useFetchTableData';
import InvestmentAccountService from 'services/InvestmentAccount';
import ModulesService from 'services/Modules';
import AdminContainer from 'components/Admin/AdminContainer';
import { useLocation, useParams } from 'react-router-dom';
import handlePromise from 'utils/Promise';
import { LoadingContext } from 'context/Loading';
import { getFile } from 'utils/downloadBase64';

function Accounts() {
  const { id, type } = useParams();
  const location = useLocation();
  const dataLocation:any = location?.state;
  const { toggleLoading } = useContext( LoadingContext );

  const { fetchData, data, dataLoading } = useFetchTableData( {
    promise: ( params ) => {
      if ( type === 'user' && id ) {
        return InvestmentAccountService.getAllBackOffice( { ...params, contactId: id } );
      }
      if ( type === 'pro' && id ) {
        return InvestmentAccountService.getAllByPromocionBackOffice(
          { ...params, promocionId: id },
        );
      }
      return InvestmentAccountService.getAllBackOffice( params );
    },
    deps: [type],
  } );

  const downloadDocument = useCallback( async ( params: any ) => {
    toggleLoading( true );
    let downloadDocumentData;
    if ( type === 'user' && id ) {
      [, , downloadDocumentData] = await handlePromise(
        InvestmentAccountService.getAllBackOffice( { ...params, contactId: id }, { responseType: 'blob' } ),
      );
    } else if ( type === 'pro' && id ) {
      [, , downloadDocumentData] = await handlePromise(
        InvestmentAccountService.getAllByPromocionBackOffice( { ...params, promocionId: id }, { responseType: 'blob' } ),
      );
    } else {
      [, , downloadDocumentData] = await handlePromise(
        InvestmentAccountService.getAllBackOffice( { ...params }, { responseType: 'blob' } ),
      );
    }

    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    getFile( downloadDocumentData, 'cuentas-de-inversion' );
  }, [] );

  const updateCrm = useCallback( async ( ) => {
    toggleLoading( true );

    // await handlePromise(
    //   ModulesService.updateModuleRefresh( ),
    // );

    await handlePromise(
      ModulesService.updateModuleRefreshPromotions( ),
    );
    await handlePromise(
      ModulesService.updateModuleRefreshProducts( ),
    );
    await handlePromise(
      ModulesService.updateModuleRefreshContacts( ),
    );
    await handlePromise(
      ModulesService.updateModuleRefreshInvestAccounts( ),
    );
    await handlePromise(
      ModulesService.updateModuleRefreshInvestContacts( ),
    );
    await handlePromise(
      ModulesService.updateModuleRefreshInvestPromotions( ),
    );
    await handlePromise(
      ModulesService.updateModuleRefreshContributions( ),
    );
    await handlePromise(
      ModulesService.updateModuleRefreshFounds( ),
    );

    setTimeout( () => {
      toggleLoading( false );
    }, 800 );
  }, [] );

  const updateSharedPoint = useCallback( async ( ) => {
    toggleLoading( true );

    const [,] = await handlePromise(
      ModulesService.updateModulePhotosKeys( ),
    );

    const [,modulePhotoResponse] = await handlePromise(
      ModulesService.updateModuleFinance( ),
    );

    if ( modulePhotoResponse.ok ) {
      const [,moduleDocResponse] = await handlePromise(
        ModulesService.updateModuleDoc( ),
      );

      if ( moduleDocResponse.ok ) {
        await handlePromise(
          ModulesService.updateModulePhotos( ),
        );

        setTimeout( () => {
          toggleLoading( false );
        }, 800 );
      } else {
        setTimeout( () => {
          toggleLoading( false );
        }, 800 );
      }
    } else {
      setTimeout( () => {
        toggleLoading( false );
      }, 800 );
    }
  }, [] );

  let headerTitle = type === 'inv' ? dataLocation?.nameCuentadeInversion : '';
  headerTitle = type === 'user' ? dataLocation?.fullName : headerTitle;
  headerTitle = type === 'pro' ? dataLocation?.codigoPromocion : headerTitle;

  return (
    <AdminContainer
      credentialState={dataLocation?.credentialState}
      extraData={dataLocation?.extraData}
      headerTitle={headerTitle}
      type={type}
      id={id ? Number( id ) : undefined}
    >
      <AccountsView
        accounts={data}
        fetchData={fetchData}
        isLoading={dataLoading}
        downloadDocument={downloadDocument}
        updateCrm={updateCrm}
        updateSharedPoint={updateSharedPoint}
        type={type}
      />
    </AdminContainer>
  );
}

export default Accounts;
