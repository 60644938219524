import api from './ApiUpdate';

export default {
  updateModuleRefresh() {
    return api.get( 'api/modules/refresh' );
  },
  updateModuleRefreshPromotions() {
    return api.get( 'api/modules/refreshpromotions' );
  },
  updateModuleRefreshProducts() {
    return api.get( 'api/modules/Refreshproducts' );
  },
  updateModuleRefreshContacts() {
    return api.get( 'api/modules/refreshcontacts' );
  },
  updateModuleRefreshInvestAccounts() {
    return api.get( 'api/modules/refreshcuentasinversion' );
  },
  updateModuleRefreshInvestContacts() {
    return api.get( 'api/modules/refreshinversionversuscontactos' );
  },
  updateModuleRefreshInvestPromotions() {
    return api.get( 'api/modules/refreshinversionversuspromocion' );
  },
  updateModuleRefreshContributions() {
    return api.get( 'api/modules/refreshaportesreparto' );
  },
  updateModuleRefreshFounds() {
    return api.get( 'api/modules/refreshpromocionversuspromocion' );
  },
  updateModuleFinance( ) {
    return api.get( 'api/modules/informesfinancieros' );
  },
  updateModuleDoc( ) {
    return api.get( 'api/modules/documentacion' );
  },
  updateModulePhotos( ) {
    return api.get( 'api/modules/avancefotografico' );
  },
  updateModulePhotosKeys( ) {
    return api.get( 'api/modules/avancefotograficokeys' );
  },
};
