const formatPhoneNumber = ( str: any ) => {
  // Filter only numbers from the input
  const cleaned = ( `${str}` ).replace( /\D/g, '' );

  // Check if the input is of correct length
  const match = cleaned.match( /^(\d{3})(\d{3})(\d{4})$/ );

  if ( match ) {
    return `${match[1]} ${match[2]} ${match[3]}`;
  }

  return null;
};

const formatCurrency = '0.0,[] $';
const formatCurrencyOneDecimal = '0.0,[] $';
const formatCurrencyNoDecimal = '0,0 $';// 0.0[,]00 $
const formatCurrencyNoDecimalAbbrev = '0.0,[] a $';// 0.0[,]00 $

const formatPercent = '0.0,[] %';
const formatPercentOneDecimal = '0,0.0 %';

const formatRegularNumber = '0.0,[]';
const formatNumberNoDecimal = '0,0';// 0.0[,]00 $

export {
  formatPhoneNumber,
  formatRegularNumber,
  formatCurrency,
  formatCurrencyOneDecimal,
  formatPercent,
  formatPercentOneDecimal,
  formatCurrencyNoDecimal,
  formatCurrencyNoDecimalAbbrev,
  formatNumberNoDecimal,
};
