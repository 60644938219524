import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BodyContent from 'components/BodyContent';
import { DTable } from 'types/table';
import RMobileTable from 'components/MobileTable';
import { HiOutlineDownload } from 'react-icons/hi';
import { BsChevronLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

function ActiveProjects( {
  projects, fetchData, isLoading, downloadFile,
}:{
  isLoading?:boolean;
  projects:DTable;
  downloadFile: ( )=>void ;
  fetchData: ( params:any, pageSize:number )=>void ;
} ) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BodyContent
      title={(
        <>
          <Row className="m-3">
            <Col xs={{ span: 6 }} className="p-0 m-0">
              <Button
                variant="link"
                size="sm"
                className="text--medium p-0"
                onClick={() => {
                  navigate( -1 );
                }}
              >
                <BsChevronLeft size={12} className="me-2" />
                <span className="me-2 text--normal">{t( 'back' )}</span>
              </Button>
            </Col>
          </Row>
          <Row className="m-3">
            <Col xs={{ span: 10, order: 1 }} className="p-0 m-0">
              <h3 className="text-ellipsis text--xmmd text-secondary">{t( 'dashboard.activeProjects' )}</h3>
            </Col>
            <Col xs={{ span: 2, order: 2 }} className="text-end p-0 m-0">
              <Button
                variant="link"
                size="sm"
                className="text--medium p-0"
                onClick={downloadFile}
              >
                <HiOutlineDownload size={22} />
              </Button>
            </Col>
          </Row>
        </>
      )}
    >
      <div className="d-md-none">
        <RMobileTable
          data={projects.data}
          pageCount={projects.pageCount}
          fetchData={fetchData}
          isLoading={isLoading}
          specialText={t( 'beforeFee' )}
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          header={( filters, setFilters ) => (
            <Row className="align-items-center">
              <Col lg={9} sm={8} xs={6} />
            </Row>
          )}
          defaultPageSize={5}
          paginationOptions={['5', '10', '20']}
        />
      </div>
    </BodyContent>
  );
}

export default ActiveProjects;
