import React from 'react';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DarkTile from 'components/DarkTile';
import LightTile from 'components/LightTile';
import BodyContent from 'components/BodyContent';
import icon1 from 'assets/img/dashboard-icon3.svg';
import icon2 from 'assets/img/dashboard-icon2.svg';
import icon16 from 'assets/img/dashboard-icon6.svg';
import icon3 from 'assets/img/project-icon1.svg';
import icon4 from 'assets/img/project-icon2.svg';
import icon6 from 'assets/img/project-icon4.svg';
import icon8 from 'assets/img/project-icon6.svg';
import icon9 from 'assets/img/project-icon7.svg';
import icon10 from 'assets/img/project-icon8.svg';
import icon12 from 'assets/img/project-icon10.svg';
import icon13 from 'assets/img/project-icon11.svg';
import icon14 from 'assets/img/ROI_estAntes_white.svg';
import icon17 from 'assets/img/ROI_estDesp_white.svg';
import icon15 from 'assets/img/dashboard-icon5.svg';

import { KpiResumeProject } from 'types/Project';
import numeral from 'numeral';
import CharDoughnut from 'components/Char/CharDoughnut';
import {
  formatPercent, formatPercentOneDecimal, formatRegularNumber, formatNumberNoDecimal,
} from 'utils/formats';
import CharProgress from 'components/Char/CharProgress';
import Documents from 'components/Dashboard/Documents';
import PhotographicAdvance from 'components/Dashboard/PhotographicAdvance';
import MillionNumeral from 'components/MillionNumeral';

type ProjectProps = {
  onOpenVideoModal: ( param: any, key: any ) => void;
  getAvanceFotografico: ( key: any ) => void;
  downloadFile: ( ) => void;
  downloadFilePdf: ( ) => void;
  kpiProject: KpiResumeProject | null ;
  financingTypes: any[] | undefined ;
  marketingSummary: any[] | undefined ;
  documents: any[] | undefined ;
  periods: any[] ;
  photographicAdvance: [] | undefined;
  workProgress: { conceptosAvanceObra:[] | undefined; avanceGeneral:number } ;
};

function Project( {
  onOpenVideoModal, kpiProject, marketingSummary, financingTypes, workProgress, documents, periods,
  photographicAdvance, getAvanceFotografico, downloadFile, downloadFilePdf,
}:ProjectProps ) {
  const { t } = useTranslation();

  return (
    <BodyContent
      showTitleInTablet
      title={(
        <div className="px-3 pt-3 pb-4 p-md-0 pb-md-4">
          <Row className="d-flex justify-content-md-between">
            <Col md={6}>
              <h1 className="mb-2">
                {kpiProject?.codigoPromocion}
              </h1>
            </Col>
            {kpiProject ? (
              <Col md={6} className="d-none d-lg-block text--right">
                {kpiProject.informeFinanciero && (
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="py-2 text--normal btn--rounded"
                  onClick={downloadFile}
                >
                  <div>
                    {t( 'lastReport' )}
                  </div>
                </Button>
                )}
                <Button
                  size="sm"
                  className="py-2 text--normal btn--rounded ms-2 px-5"
                  onClick={downloadFilePdf}
                >
                  <div className="px-2">
                    {t( 'movements' )}
                  </div>
                </Button>
              </Col>
            ) : null}

          </Row>
          {!!kpiProject?.descripcion && (
          <p className="mb-0" dangerouslySetInnerHTML={{ __html: `${kpiProject?.descripcion?.toString().replaceAll( '\n', '<br />' )}` }} />
          )}
          {kpiProject ? (
            <Row className="mt-2 d-lg-none">
              <Col md="auto" className="text-center text-md-start">
                {kpiProject.informeFinanciero && (
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="py-2 text--normal btn--rounded mt-4 mt-md-2"
                  onClick={downloadFile}
                >
                  <div>
                    {t( 'lastReport' )}
                  </div>
                </Button>
                )}
              </Col>
              <Col md="auto" className="text-center text-md-start">
                <Button
                  size="sm"
                  className="py-2 text--normal btn--rounded  px-5 mt-4 mt-md-2"
                  onClick={downloadFilePdf}
                >
                  <div className="px-2">
                    {t( 'movements' )}
                  </div>
                </Button>
              </Col>
            </Row>
          ) : null}
        </div>
      )}
    >
      <div className="row align-items-stretch g-3 g-md-4 mb-4">
        <DarkTile
          icon={icon1}
          title={( <MillionNumeral number={kpiProject?.capitalInvertido} /> )}
          subtitle={t( 'projects.myInvestedCapital' )}
        />
        <DarkTile
          icon={icon2}
          title={( <MillionNumeral number={kpiProject?.beneficioEstimado} /> )}
          subtitle={t( 'projects.myEstimatedProfit' )}
          subtitle2={t( 'beforeFee' )}
        />
        <DarkTile
          icon={icon15}
          title={( <MillionNumeral number={kpiProject?.repartosRecibidos} /> )}
          subtitle={t( 'projects.deliveriesReceived' )}
          subtitle2={kpiProject?.antesFeeExito ? t( 'beforeFee' ) : t( 'beforeFee2' )}
        />
        <DarkTile
          icon={icon16}
          title={( <MillionNumeral number={kpiProject?.proximosRepartos} /> )}
          subtitle={t( 'projects.upcomingDeliveries' )}
          subtitle2={kpiProject?.antesFeeExito ? t( 'beforeFee' ) : t( 'beforeFee2' )}
        />
      </div>

      <h3 className="text--lg mb-4 text-primary">{t( 'projects.projectOverview' )}</h3>

      <div className="row align-items-stretch row-cols-xl-5 row-cols-md-3 row-cols-2 g-3 g-md-4 mb-4">
        <LightTile
          icon={icon3}
          title={( <MillionNumeral number={kpiProject?.capitalTotal} /> )}
          subtitle={t( 'Capital total inver.' )}
        />
        <LightTile
          icon={icon4}
          title={( <MillionNumeral number={kpiProject?.beneficioNeto} /> )}
          subtitle={t( 'Beneficio neto est.' )}
          subtitle2={t( 'beforeTaxes' )}
        />
        <LightTile
          icon={icon14}
          title={numeral( kpiProject?.roiEstimado
            ? Number( kpiProject?.roiEstimado ) / 100 : 0 ).format( formatPercentOneDecimal )}
          subtitle={t( 'ROE est. antes de impuestos' )}
          subtitle2={t( 'beforeTaxes' )}
          iconSize={43}
        />
        <LightTile
          icon={icon6}
          title={numeral( kpiProject?.tirEstimado
            ? Number( kpiProject?.tirEstimado ) / 100 : 0 ).format( formatPercentOneDecimal )}
          subtitle={t( 'TIR est. antes de impuestos' )}
          subtitle2={t( 'beforeTaxes' )}
        />

        <LightTile
          icon={icon17}
          title={numeral( kpiProject?.roiEstimadoDespuesImpuestos
            ? Number( kpiProject?.roiEstimadoDespuesImpuestos ) / 100
            : 0 ).format( formatPercentOneDecimal )}
          subtitle={t( 'ROE est. después  de impuestos' )}
          subtitle2={t( 'beforeFee' )}
          iconSize={43}
        />

        <LightTile
          icon={icon8}
          title={numeral( kpiProject?.bcr ? Number( kpiProject?.bcr ) / 100
            : 0 ).format( formatPercent )}
          subtitle={t( 'BCR' )}
          subtitle2={t( 'beforeTaxes' )}
        />

        <LightTile
          icon={icon10}
          title={numeral( kpiProject?.ltv ? Number( kpiProject?.ltv ) / 100
            : 0 ).format( formatPercent )}
          subtitle={t( 'LTV' )}
        />

        <LightTile
          icon={icon13}
          title={`${kpiProject?.trimFin ? kpiProject?.trimFin?.toString().toUpperCase() : ''} `}
          subtitle={t( 'Trim. Fin' )}
        />
        <LightTile
          icon={icon9}
          title={`${kpiProject?.plazoInversion ? kpiProject?.plazoInversion : ''} ${kpiProject?.plazoInversion ? t( 'meses' ) : ''}`}
          subtitle={t( 'Plazo de Inversión' )}
        />
        <LightTile
          icon={icon12}
          title={`${kpiProject?.superficieEdificable ? numeral( kpiProject?.superficieEdificable ).format( formatRegularNumber ) : ''} m2`}
          subtitle={t( 'Sup. Edificable' )}
        />
        {!!kpiProject?.salesRevenueTotal
        && (
        <LightTile
          icon={icon3}
          title={( <MillionNumeral number={kpiProject?.salesRevenueTotal} /> )}
          subtitle={t( 'Total Ingresos por venta' )}
        />
        )}
        {!!kpiProject?.inversionTotal
        && (
        <LightTile
          icon={icon4}
          title={( <MillionNumeral number={kpiProject?.inversionTotal} /> )}
          subtitle={t( 'Inversión Total' )}
        />
        )}
        {!!kpiProject?.inmueblesEnVenta
        && (
        <LightTile
          icon={icon12}
          title={`${kpiProject?.inmueblesEnVenta ? numeral( kpiProject?.inmueblesEnVenta ).format( formatNumberNoDecimal ) : '0'}`}
          subtitle={t( 'Inmuebles a la venta' )}
        />
        )}
      </div>

      <Row>
        <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }} xl={{ span: 4, order: 1 }}>
          <CharProgress
            itemKey="myChart3"
            dataObjs={workProgress?.conceptosAvanceObra}
            percent={workProgress?.avanceGeneral}
            title={t( 'Avance de obra' )}
            label="nombre"
            val="porcentaje"
            formatVal={formatPercentOneDecimal}
            headers={[true, true, '%']}
          />
        </Col>
        <Col xs={{ span: 12, order: 2 }} className="mt-4 mt-md-0" md={{ span: 6, order: 2 }} xl={{ span: 4, order: 2 }}>
          <CharDoughnut
            itemKey="myChart1"
            dataObjs={marketingSummary}
            title={t( 'Resumen de comercialización' )}
            label="situacion"
            val="porcentaje"
            indicators={false}
            formatVal={formatPercentOneDecimal}
            widthValue="100px"
            value="precio"
            headers={[' ', t( 'situacion' ), t( 'unidades' ), '%']}
          />
        </Col>
        <Col xs={{ span: 12, order: 3 }} className="mt-4 mt-xl-0" md={{ span: 6, order: 3 }} xl={{ span: 4, order: 3 }}>
          <CharDoughnut
            itemKey="myChart2"
            dataObjs={financingTypes}
            title={t( 'Estructura de financiación' )}
            label="nombre"
            val="porcentaje"
            formatVal={formatPercentOneDecimal}
            headers={[' ', t( 'nombre' ), false, '%']}
            showHeader={false}
            indicators={false}
            widthValue="70px"
            value="precio"
          />
        </Col>

        <Col xs={{ span: 12, order: 5 }} md={{ span: 12, order: 5 }} xl={{ span: 8, order: 4 }} className="mt-4">
          <Documents dataObjs={documents} />
        </Col>

        {!!periods.length
        && periods.length > 0 && (
        <Col xs={{ span: 12, order: 4 }} className="mt-4" md={{ span: 6, order: 4 }} xl={{ span: 4, order: 5 }}>
          <PhotographicAdvance
            dataObjs={periods}
            onOpenVideoModal={onOpenVideoModal}
            photographicAdvance={photographicAdvance}
            getAvanceFotografico={getAvanceFotografico}
          />
        </Col>
        )}
      </Row>

    </BodyContent>
  );
}

export default Project;
