import React, { useCallback } from 'react';
import {
  Container, Navbar, Nav, NavDropdown,
} from 'react-bootstrap';
import logo from 'assets/img/logo-white-full.png';
// import img from 'assets/img/user.png';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Profile } from 'types/User';
import Storage from 'utils/Storage';
import { logoutUser } from 'store/User';
import { ADMIN_PROFILE } from 'constants/routes';
import { useNavigate } from 'react-router';

function Header( { profile }:{ profile?:Profile } ) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutAdmin = useCallback( () => {
    Storage.clear( 'grupoimpar:token' );
    dispatch( logoutUser() );
  }, [] );
  return (

    <Navbar expand="md" className="py-0" bg="primary">
      <Container>
        <Navbar.Brand className="cursor-pointer px-0 py-2 " onClick={() => navigate( '/admin' )}>
          <img src={logo} alt="Grupo Impar" height={40} />
        </Navbar.Brand>
        <Navbar.Toggle />

        <Navbar.Collapse className="justify-content-end">
          <Nav className="nav__item-user nav__item-admin">
            <NavDropdown
              title={(
                <>
                  {profile?.photo && ( <img src={profile?.photo} alt="" className="rounded-circle avatar--xxs me-3" /> )}
                  <span>
                    {`${profile?.firstName || ''} ${profile?.lastName || ''}`}
                  </span>
                </>
                            )}
              id="user-nav-dropdown"
            >
              <NavDropdown.Item
                as={NavLink}
                to={ADMIN_PROFILE}
                // activeClassName=""
              >
                {t( 'personalArea' )}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                className="text-primary"
                onClick={logoutAdmin}
              >
                {t( 'closeSession' )}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}

export default Header;
