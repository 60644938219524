import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, Button,
} from 'react-bootstrap';
// import img from 'assets/img/user.png';
import { getImageOrigin } from 'utils/chars';
import classnames from 'classnames';
import _ from 'lodash';
import { HiChevronRight, HiChevronLeft } from 'react-icons/hi';
import moment from 'moment';
import CardLight from '../../CardLight';

type PhotographicAdvanceProps = {
  dataObjs: any | undefined ;
  photographicAdvance: [] | undefined ;
  onOpenVideoModal: ( param: any, key: any ) => void
  getAvanceFotografico: ( key: any ) => void
};
function PhotographicAdvance( {
  dataObjs, onOpenVideoModal, photographicAdvance, getAvanceFotografico,
}: PhotographicAdvanceProps ) {
  const { t } = useTranslation();
  const [keyProgress, setKeyProgress] = useState( 0 );
  const [localLoading, setLocalLoading] = useState( false );

  const img0 = photographicAdvance ? getImageOrigin( photographicAdvance, 0 ) : null;
  const item0IsVideo = _.get( photographicAdvance, '[0].urlVideo', null );
  const img1 = photographicAdvance ? getImageOrigin( photographicAdvance, 1 ) : null;
  const item1IsVideo = _.get( photographicAdvance, '[1].urlVideo', null );
  const img2 = photographicAdvance ? getImageOrigin( photographicAdvance, 2 ) : null;
  const item2IsVideo = _.get( photographicAdvance, '[2].urlVideo', null );
  const img3 = photographicAdvance ? getImageOrigin( photographicAdvance, 3 ) : null;
  const item3IsVideo = _.get( photographicAdvance, '[3].urlVideo', null );
  const img4 = photographicAdvance ? getImageOrigin( photographicAdvance, 4 ) : null;
  const item4IsVideo = _.get( photographicAdvance, '[4].urlVideo', null );

  const name = dataObjs[keyProgress] ? dataObjs[keyProgress].nombre : '';
  let keyName = dataObjs[keyProgress] ? dataObjs[keyProgress].key : '';
  const yearName = keyName.length === 6 ? keyName.substr( 0, 4 ) : null;
  keyName = keyName.length === 6 ? ( keyName.substr( 4, 2 ) * 1 ) - 1 : null;
  const previous = ( keyProgress + 1 ) < dataObjs.length;
  const next = keyProgress > 0;

  useEffect( () => {
    if ( !photographicAdvance ) {
      setLocalLoading( true );
    } else {
      setLocalLoading( false );
    }
  }, [photographicAdvance] );

  return (
    <CardLight title={t( 'Avance fotográfico' )} fullHeight={false} isLoading={localLoading}>
      {!!dataObjs && !!dataObjs.length && (
      <div className="border-bottom">
        <Row>
          <Col xs="auto" className="ps-1">
            <Button
              variant="link"
              className="p-0"
              onClick={async () => {
                if ( previous ) {
                  setKeyProgress( keyProgress + 1 );
                  setLocalLoading( true );
                  await getAvanceFotografico( dataObjs[keyProgress + 1].key );
                  setLocalLoading( false );
                }
              }}
            >
              <HiChevronLeft size={24} color={previous ? '#1D2D68' : '#DEDEDE'} />
            </Button>
          </Col>
          <Col xs className="text-center text-capitalize">
            {keyName >= 0 && yearName ? `${moment().month( keyName ).format( 'MMMM' )}, ${yearName}` : name}
          </Col>
          <Col
            xs="auto"
            className="pe-1"
          >
            <Button
              variant="link"
              className="p-0"
              onClick={async () => {
                if ( next ) {
                  setKeyProgress( keyProgress - 1 );
                  setLocalLoading( true );
                  await getAvanceFotografico( dataObjs[keyProgress - 1].key );
                  setLocalLoading( false );
                }
              }}
            >
              <HiChevronRight size={24} color={next ? '#1D2D68' : '#DEDEDE'} />
            </Button>
          </Col>
        </Row>
      </div>
      )}
      {!!img0 && (
      <div>
        <Row>
          <Col className="my-3 position-relative" onClick={() => onOpenVideoModal( 0, name )}>
            <img
              src={img0}
              alt=""
              className="w-100"
              style={{ height: 100, objectFit: 'cover', borderRadius: 3 }}
            />
            {!!item0IsVideo && (
            <Button variant="link" id="img0" onClick={() => onOpenVideoModal( 0, name )} className="button-play">
              <i className="icon-play" />
            </Button>
            )}
          </Col>
        </Row>
      </div>
      )}
      {!!img1 && (
      <div>
        <Row>
          <Col
            className={classnames( 'mb-3 position-relative', { 'pe-1': !!img2 } )}
            onClick={() => onOpenVideoModal( 1, name )}
          >
            <img
              src={img1}
              alt=""
              className="w-100"
              style={{ height: 100, objectFit: 'cover', borderRadius: 3 }}
            />
            {!!item1IsVideo && (
            <Button variant="link" id="img1" onClick={() => onOpenVideoModal( 1, name )} className="button-play">
              <i className="icon-play" />
            </Button>
            )}
          </Col>
          {!!img2 && (
          <Col className="mb-3 position-relative" onClick={() => onOpenVideoModal( 2, name )}>
            <img
              src={img2}
              alt=""
              className="w-100"
              style={{ height: 100, objectFit: 'cover', borderRadius: 3 }}
            />
            {!!item2IsVideo && (
            <Button variant="link" onClick={() => onOpenVideoModal( 2, name )} className="button-play">
              <i className="icon-play" />
            </Button>
            )}
          </Col>
          )}
        </Row>
      </div>
      )}
      {!!img3 && (
      <div>
        <Row>
          <Col
            className={classnames( 'mb-3 position-relative', { 'pe-1': !!img4 } )}
            onClick={() => onOpenVideoModal( 3, name )}
          >
            <img
              src={img3}
              alt=""
              className="w-100"
              style={{ height: 100, objectFit: 'cover', borderRadius: 3 }}
            />
            {!!item3IsVideo && (
            <Button variant="link" onClick={() => onOpenVideoModal( 3, name )} className="button-play">
              <i className="icon-play" />
            </Button>
            )}
          </Col>
          {!!img4 && (
          <Col className="mb-3 position-relative" onClick={() => onOpenVideoModal( 4, name )}>
            <img
              src={img4}
              alt=""
              className="w-100"
              style={{ height: 100, objectFit: 'cover', borderRadius: 3 }}
            />
            {!!item4IsVideo && (
            <Button variant="link" onClick={() => onOpenVideoModal( 4, name )} className="button-play">
              <i className="icon-play" />
            </Button>
            )}
          </Col>
          )}
        </Row>
      </div>
      )}
    </CardLight>
  );
}

export default PhotographicAdvance;
