import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import FundsView from 'views/User/Funds';
import { useSelector } from 'react-redux';
import { selectUserAccount } from 'store/User';
import handlePromise from 'utils/Promise';
import ProjectService from 'services/Projects';
import { FundChart, KpiResumeProject } from 'types/Project';
import { useParams } from 'react-router-dom';
import { getFile, getFileFromBase64 } from 'utils/downloadBase64';
import { LoadingContext } from 'context/Loading';
import useFetchTableData from 'hooks/useFetchTableData';

function Funds() {
  const { id } = useParams();
  const userAccountId = useSelector( selectUserAccount );
  const { toggleLoading } = useContext( LoadingContext );
  const [project, setProject] = useState<any>();
  const [kpiProject, setKpiProject] = useState<KpiResumeProject | null>( null );
  const [documents, setDocuments] = useState<[] | undefined>( );
  const [cashFlow, setCashFlow] = useState<[] | undefined>( );
  const [totalProjects, setTotalProjects] = useState<number | undefined>( );
  const [
    distribucionUsosSuperficies,
    setDistribucionUsosSuperficies,
  ] = useState<FundChart>( );
  const [
    fuentesDeIngreso,
    setFuentesDeIngreso,
  ] = useState<FundChart>( );
  const [
    balance,
    setBalance,
  ] = useState<FundChart>( );

  const [documentsInversion, setDocumentsInversion] = useState<[] | undefined>( );

  const downloadFilePdf = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getCertificadoTitularidadPdfById( userAccountId, id, { responseType: 'blob' } ),
    );

    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFile( downloadDocumentData, `${kpiProject?.codigoPromocion}-informe-por-promoción`, 'application/pdf', 'pdf' );
    }
  }, [userAccountId, id, kpiProject] );

  const downloadFiles = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getUltimoInformeFinancieroPromocion( userAccountId, id ),
    );
    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFileFromBase64( downloadDocumentData, `${kpiProject?.codigoPromocion}_ultimo-informe-financiero` );
    }
  }, [userAccountId, id, kpiProject] );

  const downloadMovementsFiles = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getUltimoInformeMovementsPromocion( userAccountId, id, { responseType: 'blob' } ),
    );

    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFile( downloadDocumentData, `${kpiProject?.codigoPromocion}-movimientos`, 'application/pdf', 'pdf' );
    }
  }, [userAccountId, id, kpiProject] );

  const getData = useCallback( async () => {
    const [, projectDataResponse, projectData] = await handlePromise(
      ProjectService.getPromocionById( userAccountId, id ),
    );
    if ( projectDataResponse.ok ) setProject( projectData );

    const [, kpiProjectResponse, kpiProjectData] = await handlePromise(
      ProjectService.getKPIPromocionById( userAccountId, id ),
    );
    if ( kpiProjectResponse.ok ) setKpiProject( kpiProjectData );

    const [, chart1Response, distribucionUsosSuperficiesData] = await handlePromise(
      ProjectService.getDistribucionUsosSuperficies( userAccountId, id ),
    );
    if ( chart1Response.ok ) {
      const distribucioData = [];

      const total = ( distribucionUsosSuperficiesData?.livingGLA || 0 )
          + ( distribucionUsosSuperficiesData?.retailGLA || 0 )
          + ( distribucionUsosSuperficiesData?.officeGLA || 0 )
          + ( distribucionUsosSuperficiesData?.parkingGLA || 0 );

      if ( distribucionUsosSuperficiesData?.livingGLA ) {
        distribucioData.push( {
          name: 'Living GLA',
          value: distribucionUsosSuperficiesData?.livingGLA,
          percent: ( ( distribucionUsosSuperficiesData?.livingGLA || 0 ) / total ) * 100,
        } );
      }
      if ( distribucionUsosSuperficiesData?.retailGLA ) {
        distribucioData.push( {
          name: 'Retail GLA',
          value: distribucionUsosSuperficiesData?.retailGLA,
          percent: ( ( distribucionUsosSuperficiesData?.retailGLA || 0 ) / total ) * 100,
        } );
      }
      if ( distribucionUsosSuperficiesData?.officeGLA ) {
        distribucioData.push( {
          name: 'Office GLA',
          value: distribucionUsosSuperficiesData?.officeGLA,
          percent: ( ( distribucionUsosSuperficiesData?.officeGLA || 0 ) / total ) * 100,
        } );
      }
      if ( distribucionUsosSuperficiesData?.parkingGLA ) {
        distribucioData.push( {
          name: 'Parking GLA',
          value: distribucionUsosSuperficiesData?.parkingGLA,
          percent: ( ( distribucionUsosSuperficiesData?.parkingGLA || 0 ) / total ) * 100,
        } );
      }
      setDistribucionUsosSuperficies( distribucioData );
    }

    const [, chart2Response, fuentesDeIngresoData] = await handlePromise(
      ProjectService.getFuentesDeIngreso( userAccountId, id ),
    );
    if ( chart2Response.ok ) {
      const ingresoData = [];

      const total = ( fuentesDeIngresoData?.noiLivingGLA || 0 )
          + ( fuentesDeIngresoData?.noiOfficeGLA || 0 )
          + ( fuentesDeIngresoData?.noiParkingGLA || 0 )
          + ( fuentesDeIngresoData?.noiRetailGLA || 0 )
          + ( fuentesDeIngresoData?.salesRevenueLiving || 0 )
          + ( fuentesDeIngresoData?.salesRevenueLiving || 0 )
          + ( fuentesDeIngresoData?.salesRevenueLiving || 0 )
          + ( fuentesDeIngresoData?.salesRevenueLiving || 0 );

      if ( fuentesDeIngresoData?.noiLivingGLA ) {
        ingresoData.push( {
          name: 'Living GLA - NOI',
          value: fuentesDeIngresoData?.noiLivingGLA,
          percent: ( ( fuentesDeIngresoData?.noiLivingGLA || 0 ) / total ) * 100,
        } );
      }
      if ( fuentesDeIngresoData?.noiOfficeGLA ) {
        ingresoData.push( {
          name: 'Office GLA - NOI',
          value: fuentesDeIngresoData?.noiOfficeGLA,
          percent: ( ( fuentesDeIngresoData?.noiOfficeGLA || 0 ) / total ) * 100,
        } );
      }
      if ( fuentesDeIngresoData?.noiParkingGLA ) {
        ingresoData.push( {
          name: 'Parking GLA - NOI',
          value: fuentesDeIngresoData?.noiParkingGLA,
          percent: ( ( fuentesDeIngresoData?.noiParkingGLA || 0 ) / total ) * 100,
        } );
      }
      if ( fuentesDeIngresoData?.noiRetailGLA ) {
        ingresoData.push( {
          name: 'Retail GLA - NOI',
          value: fuentesDeIngresoData?.noiRetailGLA,
          percent: ( ( fuentesDeIngresoData?.noiRetailGLA || 0 ) / total ) * 100,
        } );
      }
      if ( fuentesDeIngresoData?.salesRevenueLiving ) {
        ingresoData.push( {
          name: 'Sales revenue living',
          value: fuentesDeIngresoData?.salesRevenueLiving,
          percent: ( ( fuentesDeIngresoData?.salesRevenueLiving || 0 ) / total ) * 100,
        } );
      }
      if ( fuentesDeIngresoData?.salesRevenueOffice ) {
        ingresoData.push( {
          name: 'Sales revenue office',
          value: fuentesDeIngresoData?.salesRevenueOffice,
          percent: ( ( fuentesDeIngresoData?.salesRevenueOffice || 0 ) / total ) * 100,
        } );
      }
      if ( fuentesDeIngresoData?.salesRevenueParking ) {
        ingresoData.push( {
          name: 'Sales revenue parking',
          value: fuentesDeIngresoData?.salesRevenueParking,
          percent: ( ( fuentesDeIngresoData?.salesRevenueParking || 0 ) / total ) * 100,
        } );
      }
      if ( fuentesDeIngresoData?.salesRevenueRetail ) {
        ingresoData.push( {
          name: 'Sales revenue retail',
          value: fuentesDeIngresoData?.salesRevenueRetail,
          percent: ( ( fuentesDeIngresoData?.salesRevenueRetail || 0 ) / total ) * 100,
        } );
      }

      setFuentesDeIngreso( ingresoData );
    }

    const [, chart3Response, balanceData] = await handlePromise(
      ProjectService.getBalance( userAccountId, id ),
    );
    if ( chart3Response.ok ) {
      const balanceFData = [];

      const total = ( balanceData?.fondosPropios1 || 0 )
          + ( balanceData?.financiacionTotal || 0 );

      if ( balanceData?.fondosPropios1 ) {
        balanceFData.push( {
          name: 'Fondos propios',
          value: balanceData?.fondosPropios1,
          percent: ( ( balanceData?.fondosPropios1 || 0 ) / total ) * 100,
        } );
      }
      if ( balanceData?.financiacionTotal ) {
        balanceFData.push( {
          name: 'Financiación total',
          value: balanceData?.financiacionTotal,
          percent: ( ( balanceData?.financiacionTotal || 0 ) / total ) * 100,
        } );
      }

      setBalance( balanceFData );
    }

    setDocuments( undefined );
    const [, documentProjectResponse, documentProjectData] = await handlePromise(
      ProjectService.getDocumentacion( userAccountId, id ),
    );
    if ( documentProjectResponse.ok ) setDocuments( documentProjectData );

    setDocumentsInversion( undefined );
    const [, documentInversionResponse, documentInversionData] = await handlePromise(
      ProjectService.getDocumentacionInversion( userAccountId, id ),
    );
    if ( documentInversionResponse.ok ) setDocumentsInversion( documentInversionData );

    setCashFlow( undefined );
    const [, cashFlowResponse, cashFlowData] = await handlePromise(
      ProjectService.getPromocionFlujoCajaEstimado( userAccountId, id ),
    );
    if ( cashFlowResponse.ok ) setCashFlow( cashFlowData );

    setTotalProjects( undefined );
    const [, totalProjectsResponse, totalProjectsData] = await handlePromise(
      ProjectService.getProyectsByFondoId( userAccountId, id, {} ),
    );
    if ( totalProjectsResponse.ok ) setTotalProjects( totalProjectsData.totalItems );
  }, [userAccountId, id] );

  useEffect( () => {
    getData();
  }, [userAccountId, id] );

  const {
    fetchData: fetchProjects,
    data: projects,
    dataLoading: projectsLoading,
  } = useFetchTableData( {
    promise: ( params ) => ProjectService.getProyectsByFondoId( userAccountId, id, params ),
    deps: [userAccountId, id],
  } );

  return (
    <FundsView
      fundId={id as string}
      projectData={project}
      kpiProject={kpiProject}
      documents={documents}
      documentsInversion={documentsInversion}
      distribucionUsosSuperficies={distribucionUsosSuperficies}
      fuentesDeIngreso={fuentesDeIngreso}
      balance={balance}
      downloadMovementsFiles={downloadMovementsFiles}
      downloadFile={downloadFiles}
      downloadFilePdf={downloadFilePdf}
      fetchProjects={fetchProjects}
      projects={projects}
      totalProjects={totalProjects}
      projectsLoading={projectsLoading}
      cashFlow={cashFlow}
    />
  );
}

export default Funds;
